import React , { useState } from 'react';

import Modal from '../components/Modal';
import { Table, Thead, Tbody, Thr, Tr, Th, Td } from '../components/Table';

const UserMonth = (props) => {
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ modalData, setModalData ] = useState();


  const showDataClicked = (show) => {
    setModalData(show.showDate);
    setIsModalOpen(true) ;
  }

  return (
    <React.Fragment>
      {typeof(props.calendarData[0]) !== "undefined" ? (
        <React.Fragment>
          <Table>
            <Thead>
              <Thr>
                <Th>date</Th>
                <Th>control room</Th>
                <Th>status</Th>
                <Th>program</Th>
                <Th>agency</Th>
              </Thr>
            </Thead>
            <Tbody>
              {props.calendarData.map( show => {
                return (
                  <Tr key={show._id} onClick={e => showDataClicked(show)}>
                    <Td>{show.showDate.date ? (show.showDate.date.toLowerCase()) : ("")}</Td>
                    <Td>{show.showDate.controlRoom ? (show.showDate.controlRoom.toLowerCase()) : ("")}</Td>
                    <Td>{show.status ? (show.status.toLowerCase()) : ("")}</Td>
                    <Td>{show.showDate.program ? (show.showDate.program.toLowerCase()) : ("")}</Td>
                    <Td>{show.showDate.agency ? (show.showDate.agency.toLowerCase()) : ("")}</Td>
                  </Tr>
                )}
              )}
            </Tbody>
          </Table>
          {/*Check to make shure the model data has been put in the state */}
          { modalData ? (
            <Modal open={isModalOpen} setOpen={setIsModalOpen}>
              <h1>{modalData.program ? (modalData.program.toLowerCase()) : ("")}</h1>
              <h2>{modalData.agency ? (modalData.agency.toLowerCase()) : ("")}</h2>
              <h3>{modalData.date ? (modalData.date.toLowerCase()) : ("")}</h3>
              <Table>
                <Thead>
                  <Thr>
                    <Th>position</Th>
                    <Th>operator</Th>
                    <Th>status</Th>
                  </Thr>
                </Thead>
                <Tbody>
                  {modalData.operators.map(op => {
                    return (
                      <Tr key={op._id}>
                        <Td>{op.roll ? (op.roll.toLowerCase()) : ("")}</Td>
                        <Td>{op.name ? (op.name.toLowerCase()) : ("")}</Td>
                        <Td>{op.status ? (op.status.toLowerCase()) : ("")}</Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </Modal>
          ) : ( <></> )
          }
        </React.Fragment>

      ) : ( 
        <h1>no shows this month</h1>
      )}
    </React.Fragment>

  )
}

export default UserMonth;
