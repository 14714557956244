// settings

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  updateName,
} from '../redux/loginSlice';

import { UPDATE_SELF, UPDATE_PASSWORD } from '../gql/mutation';
import { ME } from '../gql/query';

import CeneredPage from '../components/CeneredPage';
import Modal from '../components/Modal';
import Form from '../components/Form';
import { ButtonWMargin } from '../components/Button';
import LoadingError from '../components/LoadingError';
import { FlexRow } from '../components/Utilities';
import AlertText from '../components/AlertText';
import GreenAlertText from '../components/GreenAlertText';


const Settings = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
 
  // presetting the values to blanks prevents a reading from undefined error
  const [ values, setValues ] = useState({
    _id: "",
    email: "",
    phone: "",
    position: "",
    city: "",
  });
  const [ newPassword, setNewPassword ] = useState();
  const [ isModalOpen, setIsModalOpen ] = useState(false);
  const [ passwordError, setPasswordError ] = useState(false);
  const [ updateError, setUpdateError ] = useState(false);
  const [ successMessage, setSuccessMessage ] = useState(false);
  
  const [ updateSelf, { loading, error } ] = useMutation(UPDATE_SELF, {
    onCompleted: () => {
      // console.log(values);
      dispatch(updateName(values));
      // Need some better feedback here....
      // history.push("/home");
      // console.log("we updated");

      sucess();
    },
    onError: () => {
      setUpdateError(true);
    }
  });
  const [ updatePassword, { loading: loading2, error: error2 } ] = useMutation(UPDATE_PASSWORD, {
    onCompleted: () => {
      // console.log("All good!");
      setIsModalOpen(false);
      sucess();
    },
    onError: () => {
      setPasswordError(true)
    }
  });
  const { data, loading: loading1, error: error1 } = useQuery(ME, {
    fetchPolicy: "cache-and-network",
  });

   // Pre fill the state with the data
  useEffect(() => {
    if (data) {
      setValues({
        ...data.me
      })
    }
  }, [data])

  const onChange = (e)=> {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  const submitPassword = (e)=> {
    e.preventDefault();

    // console.log(newPassword)
    if (newPassword.password === newPassword.password_confirm) {
      updatePassword({
        variables: {
          _id: data.me._id,
          password: newPassword.password
        }
      });
    } else {
      setPasswordError(true)
      setTimeout(() => {
        setPasswordError(false)
      }, 30000);
      // console.log("error")
    }

  }

  const newPasswordChange = (e) => {
    setNewPassword({
      ...newPassword,
      [e.target.name]: e.target.value
    });
  };

  // Loading and error for ME 
  // for some reason it is only partially rendering so using a hook gives it 
  // the extra render that it needs.
  useEffect(() => {
    if (loading1 || error1) {
      return (
        <LoadingError loading={loading1} error={error1} />
      )
    }
  }, [loading1, error1])


  const sucess = () => {
    setSuccessMessage(true);
    setTimeout(() => {
      setSuccessMessage(false);
    }, 10000);
  };


  return(
    <CeneredPage>
      <h1>settings</h1>
      { updateError ? ( 
        <AlertText>error updating information</AlertText>
      ) : ( <></> )}
      { successMessage ? ( 
        <GreenAlertText>successfully updated information</GreenAlertText>
      ) : ( <> </> )}
      { loading || error ? ( 
        <LoadingError loading={loading} error={error} />
      ) : ( <></> )}
      <Form
        onSubmit={ e => {
          e.preventDefault();
          //console.log(values);
          
          updateSelf({
            variables: {
              ...values
            }
          });
          
      }}>
        <label htmlFor="email">email</label>
        <input
          required
          type="email"
          id="email"
          name="email"
          placeholder="email@example.com"
          onChange={onChange}
          value={values.email}
          autoFocus
        />
        <label htmlFor="phone">phone</label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="123-456-7890"
          onChange={onChange}
          value={values.phone}
        />
        <label htmlFor="city">city</label>
        <input
          type="text"
          id="city"
          name="city"
          placeholder="chicago"
          onChange={onChange}
          value={values.city}
        />
        <label htmlFor="position">position</label>
        <input
          type="text"
          id="position"
          name="position"
          placeholder="a/v"
          onChange={onChange}
          value={values.position}
        />


        <FlexRow>
          <ButtonWMargin onClick={() => history.push("/home")}>cancel</ButtonWMargin>
          <ButtonWMargin type="submit">submit</ButtonWMargin>
        </FlexRow>
        <ButtonWMargin onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }} >change password</ButtonWMargin>
      </Form> 
      <Modal open={isModalOpen} setOpen={setIsModalOpen}> 
        <h2>change password</h2>
        { loading2 || error2 ? (
          <LoadingError loading={loading2} error={error2} />
        ) : (
          <React.Fragment>
            { passwordError ? (
              <AlertText>error with password</AlertText>
            ) : ( <></> ) }
            <Form onSubmit={submitPassword} >
              <label htmlFor="password">new password</label>
              <input
                required
                type="password"
                autoComplete="new-password"
                id="password"
                name="password"
                placeholder="password"
                onChange={newPasswordChange}
              />
              <label htmlFor="password_confirm">confirm password</label>
              <input
                required
                type="password"
                autoComplete="new-password"
                id="password_confirm"
                name="password_confirm"
                placeholder="password"
                onChange={newPasswordChange}
              />
              <ButtonWMargin type="submit">submit</ButtonWMargin>
            </Form> 
          </React.Fragment>
        )}
      </Modal>
    </CeneredPage>
  );
};

export default Settings;

