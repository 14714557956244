import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { GET_USERS } from '../gql/query';
import { DELETE_USER } from '../gql/mutation';

import { EditPen, CheckFill, CheckOutline, DeleteTrashcan } from '../components/Icons';

import CeneredPage from '../components/CeneredPage';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import Modal from '../components/Modal';
import { ButtonWMargin, TextButton } from '../components/Button';
import LoadingError from '../components/LoadingError';
import { Table, Thead, Thr, Tbody, Tr, Th, Td } from '../components/Table';
import { FlexRow } from '../components/Utilities';

const AdminHome = (props)=> {
  const history = useHistory();
  const { data, loading, error, refetch } = useQuery(GET_USERS, {
    fetchPolicy: "cache-and-network",
  });
  const [ deletUser, { loading: loading1, error: error1 } ] = useMutation(DELETE_USER,{
    onCompleted: () => {
      finishWithModal();
    }
  });
  const [ selectedUser, setSelectedUser ] = useState();
  const [ isCreateOpen, setIsCreateOpen ] = useState(false);
  const [ isDeleteOpen, setIsDeleteOpen ] = useState(false);
  const [ isEditOpen, setIsEditOpen ] = useState(false);
  const [ allSelected, setAllSelected ] = useState(false);
  const [ users, setUsers ] = useState();

  useEffect(() => {
    if (data) {
      setUsers(data.users.map(us => {
        return {
          ...us,
          selected: false,
        }
      }));
    }
  }, [data])

  // refect the users when done with the modal
  const finishWithModal = () => {
    refetch();
    setIsCreateOpen(false);
    setIsEditOpen(false);
    setIsDeleteOpen(false);
  }


  const emailSelected = (e) => {
    let emails = [];
    
    // Pull all of the selected emails
    for (let i = 0; i < users.length; i ++) {
      if (users[i].selected) {
        emails.push(users[i].email);
      }
    }

    // Join them together
    emails = emails.join(", ");
    
    // create the link
    let link = document.createElement("a");
    // Hide the link
    link.style.visibility = 'hidden';
    // Set the attribute for mailto
    link.setAttribute("href", `mailto:${emails}`);
    // Open in a new tab
    link.setAttribute("target", "_blank");
    // add the link to the body
    document.body.appendChild(link);
    // click it
    link.click();
    // remove it
    document.body.removeChild(link);
  }
  // const handleRowClick = (


  const handleCheckClick = (e, line) => {
    e.stopPropagation();
    e.preventDefault();

    // Toggle the selected for the user that was selected.
    setUsers(users.map(user => {
      if (user._id === line._id) {
        user.selected = !line.selected;
        return user;
      } else {
        return user;
      }
    }));
  }


  const selectAllClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setUsers(users.map(user => {
      user.selected = !allSelected;
      return user;
    }));

    // Switch the state
    setAllSelected(!allSelected);


  }

  // Stall here waiting for the users to be populated into the state
  if (loading || error || !users) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return (
    <CeneredPage>
      <h1>welcome</h1>
      <FlexRow> 
        <ButtonWMargin onClick={emailSelected}>email selected</ButtonWMargin>
        <ButtonWMargin onClick={() => setIsCreateOpen(true)} >create user</ButtonWMargin>
      </FlexRow>
      <Table>
        <Thead>
          <Thr>
            <Th onClick={selectAllClick} >
                  { allSelected ? 
                    ( <CheckFill /> ) : 
                    ( <CheckOutline />)
                  }
            </Th>
            <Th>name</Th>
            <Th>permissions</Th>
            <Th>email</Th>
            <Th>phone</Th>
            <Th>city</Th>
            <Th>position</Th>
            <Th>
              <EditPen />
            </Th>
            <Th>
              <DeleteTrashcan />
            </Th>
          </Thr>
        </Thead>
        <Tbody>
          {users.map(user => {
            return(
              <Tr key={user._id} >
                <Td onClick={ e => handleCheckClick(e, user)}>
                  { user.selected ? 
                    ( <CheckFill /> ) : 
                    ( <CheckOutline />)
                  }
                </Td>
                <Td onClick={(e) => {
                  e.preventDefault();
                  history.push(`/user/${user.name.replace(' ', '-')}`)}}
                >
                  <TextButton>
                    {user.name ? (user.name.toLowerCase()) : ("")}
                  </TextButton>
                </Td>
                <Td>{user.permissions ? (user.permissions.toLowerCase()) : ("")}</Td>
                <Td>{user.email ? (user.email.toLowerCase()) : ("")}</Td>
                <Td>{user.phone}</Td>
                <Td>{user.city ? (user.city.toLowerCase()) : ("")}</Td>
                <Td>{user.position ? (user.position.toLowerCase()) : ("")}</Td>
                <Td>
                  <EditPen onClick={() => {
                    setSelectedUser(user);
                    setIsEditOpen(true);
                  }}/>
                </Td>
                <Td>
                  <DeleteTrashcan onClick={() => {
                    setSelectedUser(user);
                    setIsDeleteOpen(true);
                  }}/>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Modal open={isCreateOpen} setOpen={setIsCreateOpen}> 
        <UserCreate finish={finishWithModal} />
      </Modal>
      { selectedUser ? (
        <React.Fragment>
          <Modal open={isEditOpen} setOpen={setIsEditOpen}>
            <UserEdit initValues={selectedUser} finish={finishWithModal} />
          </Modal>
          <Modal open={isDeleteOpen} setOpen={setIsDeleteOpen}>
            <h2>do you wish to delete {selectedUser.name}?</h2>
            <LoadingError loading={loading1} error={error1} />
            <FlexRow>
              <ButtonWMargin onClick={finishWithModal}>no</ButtonWMargin>
              <ButtonWMargin onClick={ () => {
                deletUser({ 
                  variables: {
                    _id: selectedUser._id
                  }
                })
              }}>yes</ButtonWMargin>
            </FlexRow>
          </Modal>
        </React.Fragment>
      ) : ( <></> )}
    </CeneredPage>
  )
};

export default AdminHome;
