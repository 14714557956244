import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectPermissions
} from '../redux/loginSlice';

import OperatorHome from './OperatorHome';
import AdminHome from './AdminHome';

const Home = () => {
  const permissions = useSelector(selectPermissions);
  // const permissions = localStorage.getItem('spg-calendar-permissions');

  return (
    <React.Fragment>
      { permissions === 'OPERATOR' ? (
        <OperatorHome />
      ) : ( 
        <AdminHome />
      )}
    </React.Fragment>
  )
}

export default Home;
