import styled from 'styled-components';


const DDWrapper = styled.div`
  position: relative;
  min-width: 220px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //margin: 1em;

  z-index: 1;

  
`;

const DDHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding 10px;
  border: 3px solid #222222;

  :hover {
    // background: #A5A5A5;
    // background-color: #335a8e;
    background-color: #145B91;
    color: #fff;
  }


`;

const DDHeaderTitle = styled.div`
  display: flex;
  justify-content: space-around;
 `;

const DDList = styled.div`
  top: 44px;
  width: 100%;
  position: absolute;
  z-index: 10;
  background: #fff;

`;

const DDListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding 5px;
  border-top: 3px solid #222222;
  border-left: 3px solid #222222;
  border-right: 3px solid #222222;

  :nth-last-child(1) {
    border-bottom: 3px solid #222222;
  }

  :hover {
    background-color: #145B91;
    color: #fff;

  }

`;


export {
  DDWrapper,
  DDHeader,
  DDHeaderTitle,
  DDList,
  DDListItem,
}

