import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  selectLogin,
  selectPermissions
} from '../redux/loginSlice';


import Layout from '../components/Layout';
import Root from './Root';
import Home from './Home';
import User from './User';
import Settings from './Settings';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';

const Pages = () => {
  return (
    <Router>
      <Layout>
        <Route exact path='/' component={Root} />
        <PrivateRoute 
          path='/home' 
          component={Home} 
          rolls={['ADMIN', 'OPERATOR']}
        />
        <PrivateRoute 
          path='/settings' 
          component={Settings} 
          rolls={['ADMIN', 'OPERATOR']}
        />

        <PrivateRoute 
          path='/user/:id' 
          component={User} 
          rolls={['ADMIN']}
        />
        <PrivateRoute 
          path='/usercreate' 
          component={UserCreate} 
          rolls={['ADMIN']}
        />
        <PrivateRoute 
          path='/useredit' 
          component={UserEdit} 
          rolls={['ADMIN']}
        />
      </Layout>
    </Router>
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = useSelector(selectLogin);
  const permissions = useSelector(selectPermissions);
  return (
    <React.Fragment>
      <Route
        {...rest}
        render={props =>
            (isLoggedIn === true && (rest.rolls.includes(permissions) || !rest.rolls)) ? (
            <React.Fragment>
              <Component {...props} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Redirect
                to={{
                  pathname: '/',
                  state: { from : props.location }
                }}
              />
            </React.Fragment>
          )
        }
      />
    </React.Fragment>
  );
};


export default Pages;
