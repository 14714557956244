import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  login,
  selectLogin,
} from '../redux/loginSlice';

import { SIGN_IN } from '../gql/mutation';

import CenteredPage from '../components/CeneredPage';
import Form from '../components/Form';
import LoadingError from '../components/LoadingError';
import Button from '../components/Button';
import AlertText from '../components/AlertText';

const Root = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const [ message, setMessage ] = useState("");
  const [ values, setValues ] = useState();
  const [ signIn, { loading, error } ] = useMutation(SIGN_IN, {
    onCompleted: data => { 
      dispatch(login(data.signIn));
      props.history.push("/home");
    },
    onError: (err) => {
      setMessage("error with username or password")
      console.log(err);
    }
  });

  // If already logged in, redirect the user to home
  useEffect(() => {
    if(isLoggedIn) {
      props.history.push("/home");
    }
  // Is this needed?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange =  e => {
    e.preventDefault();
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  // for some reason it is only partially rendering so using a hook gives it 
  // the extra render that it needs....but only on safari
  useEffect(() => {
    if (loading || error) {
      return (
        <LoadingError loading={loading} error={error} />
      )
    }
  }, [loading, error])


  return (
    <CenteredPage> 
      <AlertText>{message}</AlertText>
      <Form onSubmit={ e => {
        e.preventDefault();
        signIn({ 
          variables: {
            ...values
        }
      })}}>
        <label htmlFor="username">username</label>
        <input
          required
          type="text"
          id="email"
          name="email"
          placeholder="username"
          autoComplete="username"
          onChange={onChange}
        />
        <label htmlFor="password">password</label>
        <input
          required
          type="password"
          id="password"
          name="password"
          placeholder="password"
          autoComplete="current-password"
          onChange={onChange}
        />
        <Button type="submit">submit</Button>
      </Form>
    </CenteredPage>
  )
}

export default Root;
