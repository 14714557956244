// The Modal

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const ModalDiv = styled.div`
  display: block;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: inherit;
  background-color: rgba(0,0,0,0.4);

`;

const ModalBlock = styled.div`
  background-color: #fff;
  margin: 15% auto;  /* 15% from the top and centered */
  padding: 20px;
  border: 3px solid #222222;
  width: 80%

`;

const ModalContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`;

const Close = styled.span`
  color: black;
  float: right;
  font-size: 36px;
  font-weight: bold;
  padding: 0px;
  margin: 0px;

  
  :hover,
  :focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  
`;

const Modal = (props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  //

  // On any change made by the parent, do the appropriate change here
  // in the child
  useEffect(() => {
    if (props.open === true) {
      setIsOpen(true) ;
    } else if (props.open === false) {
      setIsOpen(false);
    }
  }, [props] );

  // Close the window and let the parent know it's closed
  const close = () => {
    setIsOpen(false);
    props.setOpen(false);
  }

  // Render the block...the close X might not be the best placed....
  return (
    <React.Fragment>
    { isOpen ? ( 
        <ModalDiv>
          <ModalBlock>
            <Close onClick={close}>&times;</Close> 
            <ModalContent>
              {props.children}
            </ModalContent>
          </ModalBlock>
        </ModalDiv>
      ) : ( <></> )
      }
    </React.Fragment>
  )
};


export default Modal;
