import React, { useEffect } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { store } from './redux/store';
import { logout } from './redux/loginSlice';

import Pages from './pages';
import GlobalStyles from './GlobalStyles';

// Build the apollo links
const uri = process.env.REACT_APP_DB_API_URI;
const httplink = createHttpLink({ uri});
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: store.getState().login.jwt || ''
    }
  }
});
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': 
          // If the error code is unauthenticated
          
          // Do we need to clear the apollo cache?
          store.dispatch(logout());
          break;
        
        default: 
          // Otherwise just spit out the error into the console.
          console.log(`GraphQL Error: Message: ${err.message}, Location: ${err.location}, Path: ${err.path}`)
          console.log(err);

        break
      }
    }
  }
});

// Create the memory cache
const cache = new InMemoryCache();
// create the apollo client concatting the links together
const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httplink)),
  connectToDevTools: true, // link to production env??
  cache,
});

const App = () =>  {
  // Set the title of the website
  useEffect(()=> {
    document.title = "spg-calendar";
  }, []);

  return (
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Pages />
    </ApolloProvider>
  );
}

export default App;
