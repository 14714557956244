import styled from 'styled-components';

const DDWrapper = styled.div`
  position: relative; 
  min-width: 180px;
  background: #0D3B5E;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  z-index: 2;

  // border: 6px solid #000;
  
`;

const DDHeader = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding 10px;

  :hover {
    background: #145B91;
  }
  

`;

const DDHeaderTitle = styled.div`
  display: flex;
  background: inherit;
  justify-content: space-around;
 `;

const DDList = styled.div`
  top: 44px;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: #0D3B5E;

`;

const DDListItem = styled.div`
  background-color: #0D3B5E;
  display: flex;
  justify-content: space-between;
  padding 5px;


  :nth-last-child(1) {
    border-bottom: none;
  }

  :hover {
    background: #145B91;
  }

  border-top: 6px solid #000;
`;


export {
  DDWrapper,
  DDHeader,
  DDHeaderTitle,
  DDList,
  DDListItem, 
}

