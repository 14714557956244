import styled from 'styled-components';

const Form = styled.form`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    display: flex;
    line-height: 2em;
    font-weight: bold;
    font-size: 16px;
  }

  input {
    font-size: 16px;
    display: flex;
    line-height: 2em;
    border: 3px solid #222222;
    border-radius: 0px;
    width: 100%;
    margin-bottom: 1em;
  }

`;

export default Form;
