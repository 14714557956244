import React from 'react';
import styled from 'styled-components';

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;


`;


const CenteredPage = ({children})=> {
  return (
    <Centered>
      {children}
    </Centered>
  )
}

export default CenteredPage;
