import React from 'react';
import CeneredPage from '../components/CeneredPage';
import AlertText from './AlertText';

const LoadingError = (props) => {
  return(
    <CeneredPage>
      {props.loading ? (
        <h2>loading...</h2>
      ) : (<></>)}
      {props.error ? (
        <AlertText>error...</AlertText>
      ) : (<></>)}
    </CeneredPage>
  )
}

export default LoadingError;
