import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';

import { UPDATE_USER } from '../gql/mutation';

import CeneredPage from '../components/CeneredPage';
import LoadingError from '../components/LoadingError';
import UserForm from '../components/UserForm';

const UserEdit = (props) => {
  const [ values, setValues ] = useState({
    _id: "",
    name: "",
    permissions: "",
    password: "",
    city: "",
    position: "",
    email: "",
    phone: ""

  });
  const [ updateUser , { loading, error } ] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      props.finish();
    }
  });
 
  useEffect(() => {
    if(props.initValues) {
      setValues({
        ...props.initValues
      })
    }
  }, [props.initValues])

  const onSubmit = e => {
    updateUser({
      variables: {
        ...values
      }
    })
  };

  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return(
    <CeneredPage>
      <h2>edit user</h2>
      <UserForm requirePassword={false} onSubmit={onSubmit} values={values} setValues={setValues} />
    </CeneredPage>
  );
};

export default UserEdit;
