import { createGlobalStyle } from 'styled-components';
import sanitize from 'sanitize.css';

/* Dark: #0D3B5E
 * Primary: #145B91
 * Light: #62A8DE
 *
 */


export default createGlobalStyle`
  ${sanitize}

  ::selection {
    background: #62A8DE;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    color: #000;
    opacity: 1;
    /* background: #FFF; */
    line-height: 1.2;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  }

  p,
  h1 {
    padding: 5px;
    margin: 0px;

    :only-child {
      margin: 0 auto;
    }
  }

  h2 {
    padding: 5px;
    margin: 0px;
  }

  h3 {
    padding: 5px;
    margin: 0px;
    font-weight: normal;
    
  }


  a {
    padding: 5px;
    margin: 5px;
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }

  a:hover {
    color: #A5A5A5;
  }

  *:focus {
    outline: none;
  }


`;

