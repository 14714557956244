import styled from 'styled-components';

export const Table = styled.table`

  padding: 5px;
  border-collapse: collapse;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 1em;

  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

`;

export const Tbody = styled.tbody`

`;

export const Thead = styled.thead`

`;

export const Thr = styled.tr`
  
  :hover {
    background-color: #145B91;
    color: #fff;
  }

  :only-child {
    :hover {
      background: #fff;
      color: #000;
    }
  }

  :click {
    background: #fff;
  }

  @media (max-width: 950px) {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 3px solid #222222;

    margin: 10px;

    :hover {
      background: #fff;
      color: #000;
    }

  }


`;


export const Tr = styled.tr`
  :hover {
    background-color: #145B91;
    color: #fff;
  }

  :click {
    background: #fff;
  }

  @media (max-width: 950px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 3px solid #222222;

    margin: 10px;

    :hover {
      background: #fff;
      color: #000;
    }

  }


`;

export const Th = styled.th`
  padding: 8px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 3px solid #222222;
  text-align: left;

  @media (max-width: 950px) {
    border: none;
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;

  }



`;

export const Td = styled.td`
  padding: 8px;
  padding-left: 5px;
  padding-right: 5px;

  border-bottom: 3px solid #222222;
  
  @media (max-width: 950px) {
    border: none;
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;

    :first-child {
      font-weight: bold;
    }

  }
`;


