// here's all the queries.

import { gql } from '@apollo/client';

export const GET_USER_CALENDAR = gql`
  query userCalendar($name: String, $month: String) {
    userCalendar(name: $name, month: $month) {
      _id
      name
      roll
      status
          showDate {
        _id
        date
        show
        controlRoom
        status
        agency
        program
        operators {
          _id
          name
          roll
          status
        }
        notes
      }
    }
  }
`;

export const GET_MY_CALENDAR = gql`
  query myCalendar($month: String) {
    myCalendar(month: $month) {
      _id
      name
      roll
      status
          showDate {
        _id
        date
        show
        controlRoom
        status
        agency
        program
        operators {
          _id
          name
          roll
          status
        }
        notes
      }
    }
  }
`

export const GET_MONTHS = gql`
  query months {
    months
  }
`;

export const GET_USERS = gql`
  query users {
      users{
      _id
      name
      email
      phone
      city
      position
      permissions
    }
  }
`;

export const ME = gql`
  query me {
    me {
      _id
      name
      permissions
      email
      phone
      city
      position
    }
  }
`;
