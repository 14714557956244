import React from 'react';

import Form from '../components/Form';
import { FlexRow } from '../components/Utilities';
import { ButtonWMargin } from '../components/Button';
import Dropdown from '../components/Dropdown';

const permissionTypes = [ 
  { _id: "ADMIN" , name: "admin"}, 
  { _id: "OPERATOR", name: "operator"}  
];


const UserForm = (props) => {
  // The parent holds the actual state and the state is lifted
  // down here to the child
  let values = props.values;
  let setValues = props.setValues;

  // simple on change
  const onChange = (e)=> {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });
  };

  return(
      <Form
        // On submit call the onsubmit function passed down by the parent
        onSubmit={ e => {
          e.preventDefault();
          props.onSubmit();
      }}>
        <label htmlFor="name">name</label>
        <input
          required
          type="text"
          id="name"
          name="name"
          placeholder="name"
          onChange={onChange}
          value={values.name}
        />
        { props.requirePassword ? (
          <React.Fragment>
            <label htmlFor="password">password</label>
            <input
              required
              type="password"
              id="password"
              name="password"
              placeholder="password"
              onChange={onChange}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <label htmlFor="password">password</label>
            <p>leave blank for no change</p>
            <input
              type="password"
              id="password"
              name="password"
              placeholder="password"
              onChange={onChange}
            />
          </React.Fragment>
        )}
        <label htmlFor="email">email</label>
        <input
          required
          type="email"
          id="email"
          name="email"
          placeholder="email@example.com"
          onChange={onChange}
          value={values.email}
        />
        <label htmlFor="phone">phone</label>
        <input
          type="text"
          id="phone"
          name="phone"
          placeholder="123-456-7890"
          onChange={onChange}
          value={values.phone}
        />
        <label htmlFor="city">city</label>
        <input
          type="text"
          id="city"
          name="city"
          placeholder="chicago"
          onChange={onChange}
          value={values.city}
        />
        <label htmlFor="position">position</label>
        <input
          type="text"
          id="position"
          name="position"
          placeholder="a/v"
          onChange={onChange}
          value={values.position}
        />

        <Dropdown 
          title={"permissions"}
          options={permissionTypes}
          name="permissions"
          onChange={onChange}
        />
        <FlexRow> 
          <ButtonWMargin onClick={props.finish}>cancel</ButtonWMargin>
          <ButtonWMargin type="submit">submit</ButtonWMargin>
        </FlexRow>
      </Form>
  );
};

export default UserForm;
