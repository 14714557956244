import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation signIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      jwt
      permissions
      name
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $name: String!, 
    $password: String!, 
    $email: String!,
    $phone: String,
    $city: String,
    $position: String,
    $permissions: String,
  ) {
    createUser(
      name: $name,
      password: $password,
      email: $email,
      phone: $phone,
      city: $city,
      position: $position,
      permissions: $permissions
    )
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser (
    $_id: ID!,
    $name: String, 
    $password: String, 
    $email: String,
    $phone: String,
    $city: String,
    $position: String,
    $permissions: String,
  ) {
    updateUser(
      _id: $_id,
      name: $name,
      password: $password,
      email: $email,
      phone: $phone,
      city: $city,
      position: $position,
      permissions: $permissions
    )
  }
`;

export const UPDATE_SELF = gql`
  mutation updateSelf (
    $_id: ID!
    $email: String,
    $phone: String,
    $city: String,
    $position: String,
  ) {
    updateSelf(
      _id: $_id
      email: $email,
      phone: $phone,
      city: $city,
      position: $position,
    )
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword (
    $_id: ID!
    $password: String, 
  ) {
    updatePassword(
      _id: $_id,
      password: $password,
    )
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser (
    $_id: ID!
  ) {
    deleteUser(
      _id:$_id
    )
  }
`;

