// Page to create user

import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_USER } from '../gql/mutation';

import CeneredPage from '../components/CeneredPage';
import LoadingError from '../components/LoadingError';
import UserForm from '../components/UserForm';

const UserCreate = (props) => {
  const [ values, setValues ] = useState({
    name: "",
    permissions: "",
    password: "",
    email: "",
    city: "",
    position: "",
    phone: "",

  });
  const [ createUser, { loading, error } ] = useMutation(CREATE_USER, {
    onCompleted: () => {
      props.finish();
    }
  });
  
  const onSubmit = e => {
    createUser({
      variables: {
        ...values
      }
    })
  };

  if (loading || error) {
    return (
      <LoadingError loading={loading} error={error} />
    )
  }

  return(
    <CeneredPage>
      <h2>create user</h2>
      <UserForm requirePassword={true} onSubmit={onSubmit} values={values} setValues={setValues} />
    </CeneredPage>
  );
};

export default UserCreate;
