import { 
  AiOutlineCheck,
  AiOutlineCheckCircle,
  AiFillCheckCircle,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineDelete,
  AiFillEdit,
} from 'react-icons/ai';
import styled from 'styled-components';


const DeleteTrashcan = styled(AiOutlineDelete)`
  padding: 0px;
  margin: 0px;
  font-size: 1.5em;
  vertical-align: middle;
  
  :hover {
    color: #A5A5A5;
  }

  :only-child {
    :hover {
      color: #000;
    }
  }
  
  @media (max-width: 950px) {
    font-size: 2em;
  }

`;

const EditPen = styled(AiFillEdit)`
  padding: 0px;
  margin: 0px;
  font-size: 1.2em;
  vertical-align: middle;
  
  :hover {
    color: #000;
  }

  :only-child {
    :hover {
      color: #000;
    }
  }

  @media (max-width: 950px) {
    font-size: 2em;
  }

`;


const Check = styled(AiOutlineCheck)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;

  :hover {
    color: #000;
  }

  :only-child {
    :hover {
      color: #000;
    }
  } 
  @media (max-width: 950px) {
    font-size: 2em;
  }

`;



const CheckOutline = styled(AiOutlineCheckCircle)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;

  :hover {
    color: #000;
  }

  :only-child {
    :hover {
      color: #000;
    }
  } 
  @media (max-width: 950px) {
    font-size: 2em;
  }

`;

const CheckFill = styled(AiFillCheckCircle)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;

  :hover {
    color: #000;
  }

  :only-child {
    :hover {
      color: #000;
    }
  } 
  @media (max-width: 950px) {
    font-size: 2em;
  }

`;


const ArrowUp = styled(AiOutlineArrowUp)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
`;
const ArrowDown = styled(AiOutlineArrowDown)`
  font-size: 1.3em;
  vertical-align: middle;
  margin-left: 3px;
  margin-right: 3px;
`;

export {
  CheckOutline,
  CheckFill,
  Check,
  ArrowDown,
  ArrowUp,
  DeleteTrashcan,
  EditPen,
}

