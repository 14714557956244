import React from 'react';
import styled from 'styled-components';

const FooterBar = styled.footer`
  height: 40px;
  left: 0;
  bottom: 0;
  width: 100%
  backgroud: #ddd;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Footer = () => {
  return (
    <FooterBar>
      <p>
        Signature Production Group 
      </p>
    </FooterBar>
  )
}

export default Footer;
