// Page for an admin to view a user's shows

import React from 'react';
import { useLazyQuery, useQuery} from '@apollo/client';

import { GET_MONTHS, GET_USER_CALENDAR } from '../gql/query';

import LoadingError from '../components/LoadingError';
import CeneredPage from '../components/CeneredPage';
import Dropdown from '../components/Dropdown';
import UserMonth from './UserMonth';


const User = (props) => {
  const userName = props.match.params.id.split('-').join(' ');

   const [ getUserCalendar, { loading: loading2, data: calendarData, error:error2 } ] = useLazyQuery(GET_USER_CALENDAR, {
     onComplete: (data) => {
     },
     fetchPolicy: "cache-and-network"

   });
  const { data: monthData, loading:loading1, error:error1 } = useQuery(GET_MONTHS);

  // Put the monthData into a format for the drop down
  let months;
  if (monthData) {
    months = monthData.months.map(month => {
      return { _id: month, name: month.toLowerCase() }
    })
  }

  // Handle the monthdata loading
  if (loading1 || error1) {
    return (
      < LoadingError loading={loading1} error={error1}/>
    )
  }
  return (
    <CeneredPage>
      <h1>{userName.toLowerCase()}</h1>
      <Dropdown
        title={"please select a month"}
        options={months}
        name="month"
        onChange={e => getUserCalendar({ variables: { name: userName, month: e.target.value }})}
      />
      { loading2 || error2 ? (
        <LoadingError loading={loading2} error={error2} />
      ) : ( <>
        { calendarData ? (
          <UserMonth calendarData={calendarData.userCalendar} />
        ) : ( <></> )}
      </> )}
    </CeneredPage>

  )

};

export default User;
