import React, { useState, useEffect } from 'react';
import {
  DDWrapper,
  DDHeader,
  DDHeaderTitle,
  DDList,
  DDListItem,
} from './HeaderDropDownStyled';
import {
  ArrowUp,
  ArrowDown,
} from './Icons';

const Dropdown = (props) => {
  const [ isOpen, setIsOpen ] = useState(false);
  //const [ value, setValue ] = useState("Hey");
  const [ options, setOptions ] = useState(props.options.map(op => {
    return {
      ...op,
      selected: false,
    }
  }));

  useEffect(() => {
    if (isOpen) {
        window.addEventListener('click', close, {
              once: true,
            });
    } else {
        window.removeEventListener('click', close, {
          once: true,
        });
    }

    return () => {
      if (isOpen) {
          window.removeEventListener('click', close, {
            once: true,
          });
      }
    }
  }, [isOpen])

  const selectItem = (e, item) => {
    // console.log("Hey! Select an Item!");
    // e.stopPropagation();
    setOptions(options.map(option => {
      if (option._id === item._id) {
        // setTitle(`Control Room: ${item.name}`);
        option.selected = true;
        // setValue(item._id);
        props.onChange({target: { 
          value: item._id,
          name: props.name,
        }});
        return option;
      } else {
        option.selected = false;
        return option;
      }
    }))
  }


  
  const close = () => {
    setIsOpen(false);
  }

 
  return (
      <DDWrapper>
          <DDHeader
            onClick={() => setIsOpen(!isOpen)}
          >
          <DDHeaderTitle>{props.title}</DDHeaderTitle>
          {isOpen ? (
            <ArrowUp/>
          ) : ( 
            <ArrowDown/>
          )}
        </DDHeader>
        {isOpen && (
          <DDList>
            {options.map((item) => (
              <DDListItem
                key={item._id}
                onClick={(e) => selectItem(e, item)}
              >
                {item.name}
              </DDListItem>
            ))}
          </DDList>
        )}
      </DDWrapper>
  )
}

export default Dropdown;

