import styled from 'styled-components';

const Button = styled.button`
  background-color: #FFFFFF;
  border: 3px solid #222222;
  font-weight: bold;
  color: #000;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 1px;
  font-size: 16px;

  :hover {
    background-color: #145B91;
    color: #fff;

  }

  :active {
    background-color: #145B91;
    color: #fff;

  }
`;

export const ButtonWMargin = styled(Button)`
  margin: 1em;
`;

export const TextButton = styled.p`
  margin: 0px;
  padding: 0px;
  
  :hover {
    color: #000;

  }

  :active {
    color: #000;

  }

`;

export default Button;
  
