// The login slice

import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'login',
  initialState: {
    // isLoggedIn: (!!localStorage.getItem('spg-calendar-token') && !!localStorage.getItem('spg-calendar-permissions')),
    isLoggedIn: false,
    // permissions: localStorage.getItem('spg-calendar-permissions'),
    permissions: "",
    jwt: "",
    name: "",
  },
  reducers: {
    login: (state, action) => {
      // localStorage.setItem('spg-calendar-token', action.payload.jwt);
      // localStorage.setItem('spg-calendar-permissions', action.payload.permissions);
      state.isLoggedIn = true;
      state.jwt = action.payload.jwt;
      state.permissions = action.payload.permissions;
      state.name = action.payload.name.toLowerCase();
    },
    logout: state => {
      // localStorage.removeItem('spg-calendar-token');
      // localStorage.removeItem('spg-calendar-permissions');
      state.isLoggedIn = false;
      state.jwt = null;
      state.permissions = null;
      state.name = "";
    },
    updateName: (state, action) => {
      state.name = action.payload.name.toLowerCase();
    }
  },
});

export const { login, logout, updateName } = slice.actions;

export const selectLogin = state => state.login.isLoggedIn;
export const selectPermissions = state => state.login.permissions;
export const selectName = state => state.login.name;
export const selectJwt = state => state.login.jwt;

export default slice.reducer;
