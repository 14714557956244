import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import {
  logout,
  selectLogin,
  selectName,
} from '../redux/loginSlice';


import logo from '../img/spg_logo_white.png';
import Dropdown from './HeaderDropdown';

const dropdownOptions = [
  { _id: "settings", name:"settings"}, { _id: "logout", name:"logout"} ];

const HeaderBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0D3B5E;
  color: #fff;
  padding: 0;
  margin: 0;
  
`;

const Img = styled.img`
  height: 36px;
  width: 132px;
  padding: 0px;
  margin: 5px;
  margin-right: 40px;

  :hover {
    background: #145B91;
  }

  @media (max-width: 520px) {
    margin-right: 5px;

  }
`;


const CenterLabel = styled.h1`
  @media (max-width: 520px) {
    display: none;

  }

`;

const Header = (props) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectLogin);
  const name = useSelector(selectName);
  const client = useApolloClient();
  const history = useHistory();

  return (
    <HeaderBar>
      { isLoggedIn ? (
        <React.Fragment>
          <Img 
            src={logo}
            alt="logo"
            onClick={()=> history.push("/home")}  
          />
          <CenterLabel>
            calendar
          </CenterLabel>
          <Dropdown 
          title={name}
          options={dropdownOptions}
          name="here"
          onChange={ async e => {
            if (e.target.value === "logout") {
              // manually resetting the apollo client causes react to try to update
              // an unmounted component which denotes a memory leak...not where it is.
              // I think it could be a bug with apollo. It looks like there are two actibe 
              // queries when there should be one and clearStore()/resetStore() only stop one
              // of the quireies not both so the other one still exists and throws a forbidden
              // api error then tries to update a component that does not exist.
              //client.clearStore();
               
              dispatch(logout());
              client.clearStore().then(() => {
                window.location.reload(false)
              })
           
            } else if (e.target.value === "settings") {
              history.push('/settings');
            }
          }}
        />
        </React.Fragment>
      ) : ( 
        <h1>calendar</h1>
      )}
    </HeaderBar>
  )
};

export default Header;
